import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import styled from 'styled-components'

import { Box } from './Box'
import { space } from 'styled-system'

const Radio = styled.input`
  &[type='radio'] {
    display: none;
  }

  &:focus:invalid {
    border-color: ${props => props.theme.warningLight};
  }

  &:checked {
    & + label::before {
      background: ${props => props.theme.color.brandPrimary};
      border-color: ${props => props.theme.color.brandPrimary};
      box-shadow: inset 0px 0px 0px 3px #fff;
    }

    &:focus:invalid {
      & + label::before {
        background: ${props => props.theme.warningLight};
      }
    }
  }

  &:focus {
    & + label {
      outline: none;
    }
  }
`

const Label = styled.label`
  align-items: flex-start;
  color: ${props => props.theme.primaryType};
  cursor: pointer;
  display: flex;
  flex-basis: initial;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: nowrap;
  font-size: 16px;
  font-stretch: normal;
  margin-bottom: 0;
  transition: color ${props => props.theme.transition};

  &::before {
    background: #fff;
    border: 1px solid ${props => props.theme.color.greyShade5};
    border-radius: 50%;
    content: '';
    display: inline-block;
    flex-shrink: 0;
    height: 14px;
    margin-right: 4px;
    padding: 0;
    text-align: center;
    transition: box-shadow ${props => props.theme.transition},
      border ${props => props.theme.transition},
      background-color ${props => props.theme.transition};
    width: 14px;
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.color.brandPrimary};

    &::before {
      border-color: ${props => props.theme.color.brandPrimary};
      box-shadow: ${props => props.theme.color.boxShadow};
    }
  }
`

const Hint = styled.div`
  border-bottom: 1px solid transparent;
  color: ${props => props.theme.color.greyShade5};
  font-size: 12px;
  margin-left: 22px;
  margin-top: 4px;
  transition: border 0.5s;
`

const Title = styled.p`
  font-size: 12px;
  margin: 0 0 4px;
  color: ${props => props.theme.color.greyShade5};
`

export const RadioOption = ({ id, label, title, hint, pb = 3, ...rest }) => (
  <Box className="radio" pb={pb} {...pick(rest, [...Object.keys(space)])}>
    {title && <Title>{title}</Title>}
    <Radio type="radio" id={id} {...omit(rest, [...Object.keys(space)])} />
    <Label htmlFor={id}>{label}</Label>
    {hint && <Hint>{hint}</Hint>}
  </Box>
)

RadioOption.propTypes = {
  hint: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.node,
  pb: PropTypes.number,
  title: PropTypes.node
}
export default RadioOption
