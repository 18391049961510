import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { RadioOption } from '../js/components/RadioOption';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radiooption"
    }}>{`RadioOption`}</h1>
    <p><inlineCode parentName="p">{`import { RadioOption } from '@foodsby/nutrient'`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={RadioOption} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <div style={{ color: \'white\' }}>\n    <RadioOption\n      title=\"Please choose an option:\"\n      label=\"Option One\"\n      name=\"options\"\n      id=\"one\"\n      hint=\"$0.50\"\n      mb={16}\n    />\n    <RadioOption label=\"Option Two\" name=\"options\" id=\"two\" hint=\"$0.75\" />\n  </div>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      RadioOption,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <div style={{
          color: 'white'
        }}>
    <RadioOption title="Please choose an option:" label="Option One" name="options" id="one" hint="$0.50" mb={16} mdxType="RadioOption" />
    <RadioOption label="Option Two" name="options" id="two" hint="$0.75" mdxType="RadioOption" />
  </div>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      