import styled from 'styled-components'
import { flexbox, layout, space } from 'styled-system'

export const Box = styled.div`
  ${flexbox};
  ${layout};
  ${space};
`

export const Inline = styled.span`
  ${flexbox};
  ${layout};
  ${space};
`

export default { Box, Inline }
